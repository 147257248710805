<template>
	<div class="home">
		<div v-if="(getSearchResult.length === 0 && getSearch === '')" class="no__search">
            <h1>Chercher un utilisateur</h1>
            <h4>Entrez votre recherche (CAB, e-mail) dans la barre supérieure</h4>
        </div>
        <div v-else-if="(getSearchResult.length === 0 && getSearch != '')" class="no__search">
            <h1>Aucun utilisateur trouvé</h1>
            <h4>La recherche <span class="search__text">{{getSearch}}</span> n'a rien donné</h4>
        </div>
        <div v-else-if="(getSearchResult.length > 0 && getSearch != '')" class="has__search">
            <h1>Utilisateurs trouvés</h1>
			<h4>Résultats de votre recherche pour <span class="search__text">{{getSearch}}</span></h4>
			<v-data-table :headers="headers" :items="getSearchResult" class="table__search elevation-2" hide-default-footer>
				<template v-slot:item.created="{ item }">
					{{ formatedDate(item.created) }}
				</template>
				<template v-slot:item.site="{ item }">
					<v-icon color="blue lighten-2" v-if="item.site != null">
						mdi-check
					</v-icon>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-icon class="mr-2" @click="$router.push({path: '/user/' + item.id})" title="Voir infos utilisateur">
						mdi-eye-outline
					</v-icon>
				</template>
				<template v-slot:no-data>
					<v-btn color="primary" @click="initialize">
						Reset
					</v-btn>
				</template>
			</v-data-table>
        </div>
        <div v-else class="no__search">
            <h1>Chercher un utilisateur</h1>
            <h4>Entrez votre recherche (CAB, e-mail) dans la barre supérieure</h4>
        </div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { utils } from '../utils.js'

export default {
	name: 'Home',
	data() {
		return {
			headers: [
				{ text: 'CAB', value: 'cab' },
				{ text: 'Identifiant', value: 'login' },
				{ text: 'E-mail', value: 'email' },
				{ text: 'Site', value: 'site', sortable: false },
				{ text: 'Création', value: 'created' },
				{ text: '', value: 'actions', sortable: false },
			],
		}
	},
	computed: {
		...mapGetters("user", [
			"getSearch",
			"getSearchResult",
		])
	},
	methods: {
		formatedDate(date) {
			return utils.tools.formatedDate(date)
		}
	}
}
</script>

<style lang="scss" scoped>
.home {
	padding: 5%;
	text-align: center;

	h4 {
		color: #c1c1c1;
		font-weight: normal;
	}

	.table__search {
		margin-top: 50px;
	}

	.search__text {
		font-weight: bold;
		color: #64B5F6;
	}
	.no__search {
        text-align: center;
        padding-top: 10%;
        h4 {
            color: #c1c1c1;
            font-weight: normal;
        }
    }
}
</style>